import React from "react"
import Container from "@ecom/ui/components/Container"
import Img from "./Img"
import * as styles from "./bannerShortHalvaDlyaGamerov.module.scss"

import { TypeBannerPropsWithElements } from "../helpers"

export function ShortBanner({
  orderNum,
  title,
  description,
  introDescription,
  introTitle,
}: TypeBannerPropsWithElements) {
  return (
    <section className={styles.section} data-exclude={orderNum}>
      <Container>
        <div className={styles.intro}>
          <h1 className={styles.introTitle}>{introTitle}</h1>
          <p className={styles.introDescr}>{introDescription}</p>
        </div>
        <section className={styles.banner}>
          <Container className={styles.secondContainer}>
            <Img alt="bg image" className={styles.img} />
            <div className={styles.text}>
              <h1>{title}</h1>
              <div>{description}</div>
            </div>
          </Container>
        </section>
      </Container>
    </section>
  )
}
