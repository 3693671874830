import React from "react"
import Button from "@material-ui/core/Button"

import clsx from "clsx"

import * as styles from "./rightSection.module.scss"
import { handleClickHeader } from "../../../../helpers/WatcherDL/utils/handleClickHeader"

type RightSectionProps = {
  variant?: "whiteLogo" | "blackLogo"
  buttonText?: string
}

export const RightSection = ({ variant = "blackLogo", buttonText }: RightSectionProps) => (
  <div className={styles.container}>
    <Button
      classes={{ root: clsx(styles.btn, styles[variant]) }}
      onClick={() => handleClickHeader("sticky", "header")}
    >
      {buttonText}
    </Button>
  </div>
)
