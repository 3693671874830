import React from "react"

import UIHeader from "@ecom/ui/components/HeaderNew"
import { HalvaLogo } from "./Logo"
import { RightSection } from "./RightSection/RightSection"

export type HeaderProps = {
  children?: React.ReactNode
  variant?: "whiteLogo" | "blackLogo"
  buttonText?: string
}

export const HeaderAuraCard = ({
  children,
  variant = "blackLogo",
  buttonText = "Получить карту",
}: HeaderProps) => {
  const onLogoClick = () => {
    window.open("https://halvacard.ru/", "_blank")
  }

  const LogoWithLink = () => (
    <a href="https://sovcombank.ru/" target="_blank" rel="noreferrer" onClick={onLogoClick}>
      <HalvaLogo variant={variant} />
    </a>
  )

  const headerBackGround = variant === "whiteLogo" && "#292929"

  return (
    <>
      <UIHeader
        withButton={false}
        withPhone={false}
        right={<RightSection buttonText={buttonText} variant={variant} />}
        withShadow={false}
        sticky={false}
        backgroundColor={headerBackGround}
        left={<LogoWithLink />}
      >
        {children}
      </UIHeader>
    </>
  )
}
