import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"
import { useMediaQuery } from "@material-ui/core"

import Layout from "../../components/Layouts/mainPage"
import { HeaderAuraCard } from "../../components/Headers/HeaderAuracard"
import { FormPKW } from "../../components/FormPKW"
import { NewFooter } from "../../components/NewFooter"
import { Banner } from "../../components/Banners/BannerHalvaDlyaGamerov"
import { ShortBanner } from "../../components/Banners/BannerShortHalvaDlyaGamerov"
import { getPageData } from "../../helpers/getPageData"

import { PageData } from "../../interfaces/pageProps"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"

import { InfoCards } from "../../components/InfoCardsHalva"
import { HALVA_DLYA_GAMEROV_CARDS } from "../../components/InfoCardsHalva/helpers"

export default function Page({ data }: PageProps<PageData>) {
  const { noIndex, ligal } = getPageData(data)
  const isTab = useMediaQuery("(min-width:599.5px) and (max-width:959.5px)")

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  const titleForm = "Оформи карту «Халва»"

  const bannerTitle = (
    <>
      Х.Game — инструмент <br /> настоящего геймера!
    </>
  )

  const bannerDescription = "Всё лучшее в одной карте"
  const headerButton = "Оформить карту"

  const shortBannerTitle = "Плюшки:"
  const introTitle = "Как пользоваться картой"
  const shortBannerDescription = (
    <ul>
      <li>Покупка лицензионных ключей видеоигр в рассрочку</li>
      <li>Розыгрыш на киберспортивные мероприятия</li>
      <li>
        Инвайты на закрытые стримы <br /> с киберспортсменами
      </li>
    </ul>
  )

  const introDescr = isTab ? (
    <>
      Карту нужно привязать к МирПэй или пользоваться <br /> как обычно – если потребовалась карта
      на физическом носителе.
    </>
  ) : (
    <>
      Карту нужно привязать к МирПэй или пользоваться как обычно – если потребовалась карта на
      физическом носителе.
    </>
  )

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <HeaderAuraCard buttonText={headerButton} variant="whiteLogo" />
      <Banner orderNum="1" description={bannerDescription} title={bannerTitle} />
      <FourAdvantagesPoints
        title="Ваша выгода с Х.Game:"
        withTitle
        variant="halvaDlyaGamerov"
        orderNum="2"
        indent={false}
      />
      <InfoCards title="Как получить карту" cards={HALVA_DLYA_GAMEROV_CARDS} orderNum="3" />
      <ShortBanner
        title={shortBannerTitle}
        description={shortBannerDescription}
        introTitle={introTitle}
        introDescription={introDescr}
        orderNum="4"
      />
      <FormPKW
        dataLayerName="shortPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        withTimer={false}
        formBtnText="Оформить карту"
        backgroundColor="#6B3EA2"
        orderNum="5"
        emptyForm={false}
        variant="halvaDlyaGamerov"
        fioPlaceHolder="Фамилия Имя Отчество"
      />
      <NewFooter ligal={ligal} variant="halvaDlyagamerov" orderNum="6" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/pkw/halva-dlya-gejmerov/") {
        url
        notIndex
        ligal {
          text
        }
      }
    }
  }
`
